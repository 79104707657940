<template>
  <div id="#wrapper" :class="'layout bensadevot-layout '+pageShortName+'-page'">
    <!-- Header -->
    <CmsComponent v-if="components.header" :key="pageShortName + '-header'" :slider="Array.isArray(components.slider) ? components.slider[0] : components.slider" :component="Array.isArray(components.header) ? components.header[0] : components.header"></CmsComponent>
    <!-- Slider -->
    <CmsComponent v-if="components.slider" :key="pageShortName + '-slider'" :component="Array.isArray(components.slider) ? components.slider[0] : components.slider"></CmsComponent>
    <!-- Page Title -->
    <CmsComponent v-if="components.pageTitle" :key="pageShortName + '-pageTitle'" :component="Array.isArray(components.pageTitle) ? components.pageTitle[0] : components.pageTitle"></CmsComponent>
    <!-- Content -->
    <section id="content" v-if="components.content">
      <div class="content-wrap p-0">
        <CmsComponent v-for="(component, index) in components.content" :key="pageShortName + '-' + index" :component="component"></CmsComponent>
      </div>
    </section>
    <!-- Footer -->
    <CmsComponent v-if="components.footer" :key="pageShortName + '-footer'" :component="Array.isArray(components.footer) ? components.footer[0] : components.footer"></CmsComponent>
    <!-- Side Cart -->
    <CmsComponent v-if="components.sideCart" :key="pageShortName + '-sideCart'" :component="Array.isArray(components.sideCart) ? components.sideCart[0] : components.sideCart"></CmsComponent>
    <!-- Cookies -->
    <Cookies></Cookies>
  </div>
</template>

<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { utils as RouterUtils } from '@fwk-client/router/utils';
import CmsCanvasLayout from '../mixins/CmsCanvasLayout.vue';
import { languagesTypes } from '@fwk-client/store/types';
import { mapGetters } from '@fwk-node-modules/vuex';
import Cookies from '../panels/Cookies.vue';

@Component({
  components: {
    Cookies
   },
  computed: {
	...mapGetters({
			currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE,
			})
  }
})
export default class Bensadevot extends mixins(CmsCanvasLayout, GenericPage) {

  get pageShortName() {
    return RouterUtils.getRouteShortNameFromRoute(this.$route);
  }

  get components() {
    return this.$cms.components.getComponentsFromRoute(this.$route);
	}
  
  constructor() {
    super();
  }

  created() {
    if(process.env.CONSOLE == "LOG") { console.log("COMPONENT - APP - CREATED"); }
  }

  mounted() {
    this.$cms.components.onCmsRouteMounted(this.onBensadevotLayoutMounted)
  }

  destroyed() {
    this.$cms.components.offCmsRouteMounted(this.onBensadevotLayoutMounted)
  }

  onBensadevotLayoutMounted() {
    this.$root.$emit('triggerScroll');
  }

}
</script>